import request from "@/utils/request";
import { baseUrl } from "@/utils/config";

// 获取学校
export function getSchoolList(data) {
  return request({
    url: baseUrl + "/schools",
    params: data,
    method: "get",
  });
}

// 获取班级
export function schoolclasseslist(data) {
  return request({
    url: baseUrl + "/school/classes/list",
    params: data,
    method: "get",
  });
}

// 获取学生
export function schoolstudentslist(data) {
  return request({
    url: baseUrl + "/school/students/list",
    params: data,
    method: "get",
  });
}

// 学生健康档案详情
export function studentHealth(data) {
  return request({
    url: baseUrl + "/school/studentHealth",
    params: data,
    method: "get",
  });
}

// 学生健康档案详情
export function getStudentphysical(data) {
  return request({
    url: baseUrl + "/school/studentphysical/report",
    params: data,
    method: "get",
  });
}

// 体质健康-体测成绩-柱状图数据
export function getPhysicalDiagramData(data) {
  return request({
    url: baseUrl + "/school/studentphysical/getPhysicalDiagramData",
    params: data,
    method: "get",
  });
}

// 查询学校历史体测
export function getHistoricalMeasurement(data) {
  return request({
    url: baseUrl + "/school/schoolphysical/record",
    params: data,
    method: "get",
  });
}

export function getClassPhysicalDiagramData(data) {
  return request({
    url: baseUrl + "/school/classphysical/getPhysicalDiagramData",
    params: data,
    method: "get",
  });
}

// 体测项目类型
export function projectType(data) {
  return request({
    url: baseUrl + "/school/projectType",
    params: data,
    method: "get",
  });
}

//班级体质健康详情下的 接口

//年级体质健康占比
export function classHealth(data) {
  return request({
    url: baseUrl + "/school/classHealth",
    params: data,
    method: "get",
  });
}

//学校班级详情等级分布
export function classInfoHealth(data) {
  return request({
    url: baseUrl + "/school/classInfoHealth",
    params: data,
    method: "get",
  });
}

// 获取学校下所有的年级
export function grade(data) {
  return request({
    url: baseUrl + "/school/grade",
    params: data,
    method: "get",
  });
}

//学生健康档案详情（历史体质健康变化
export function studentHistoricalHealth(data) {
  return request({
    url: baseUrl + "/school/studentHistoricalHealth",
    params: data,
    method: "get",
  });
}
//班级健康档案详情（历史体质健康变化
export function classHistoricalHealth(data) {
  return request({
    url: baseUrl + "/school/classHistoricalHealth",
    params: data,
    method: "get",
  });
}

// 班级体质健康档案
export function classPhysicalReport(data) {
  return request({
    url: baseUrl + "/school/classphysical/report",
    params: data,
    method: "get",
  });
}
// 本校接口
// 本校体质健康占比
export function thisSchoolHealth(data) {
  return request({
    url: baseUrl + "/school/thisSchoolHealth",
    params: data,
    method: "get",
  });
}

// 年级体质健康档案
export function gradePhysicalReport(data) {
  return request({
    url: baseUrl + "/school/gradephysical/report",
    params: data,
    method: "get",
  });
}

// 年级柱状图
export function getGradePhysicalDiagramData(data) {
  return request({
    url: baseUrl + "/school/gradephysical/getPhysicalDiagramData",
    params: data,
    method: "get",
  });
}

// 学校体测数据-整体数据
export function schoolphysicalReport(data) {
  return request({
    url: baseUrl + "/school/schoolphysical/report",
    method: "get",
    params: data,
  });
}

// 学校体测成绩-项目率值
export function schoolgetProjectAvgReport(data) {
  return request({
    url: baseUrl + "/school/schoolphysical/getProjectAvgReport",
    method: "get",
    params: data,
  });
}

// 学校体测成绩-柱状图
export function schoolgetPhysicalDiagramData(data) {
  return request({
    url: baseUrl + "/school/schoolphysical/getPhysicalDiagramData",
    method: "get",
    params: data,
  });
}

// 教育局体测数据-整体数据
export function jyjPhysicalReport(data) {
  return request({
    url: baseUrl + "/jyj/jyjphysical/report",
    method: "get",
    params: data,
  });
}

// 教育局体测数据-柱状图
export function jyjgetPhysicalDiagramData(data) {
  return request({
    url: baseUrl + "/jyj/jyjphysical/getPhysicalDiagramData",
    method: "get",
    params: data,
  });
}

// 教育局体测数据-项目率值
export function jyjgetProjectAvgReport(data) {
  return request({
    url: baseUrl + "/jyj/jyjphysical/getProjectAvgReport",
    method: "get",
    params: data,
  });
}

// 教育局体测数据-学校排名
export function jyjgetJyjSchoolReportRank(data) {
  return request({
    url: baseUrl + "/jyj/jyjphysical/getJyjSchoolReportRank",
    method: "get",
    params: data,
  });
}
// 全区中考模考;
export function jyjphysical(data) {
  return request({
    url: baseUrl + "/school/examination/info",
    method: "get",
    params: data,
  });
}
// 推荐视频
export function recommendedVideos(data) {
  return request({
    url: baseUrl + "/school/examination/recommendedVideos",
    method: "get",
    params: data,
  });
}
export function getLatestYears(data) {
  return request({
    url: baseUrl + "/school/common/getLatestYears",
    method: "get",
    params: data,
  });
}
// 中考模考历史日期
export function examination(data) {
  return request({
    url: baseUrl + "/examination/year",
    method: "get",
    params: data,
  });
}
